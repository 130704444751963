import {
  CardContainer,
  Description,
  DescriptionValue,
  DescriptionSubValue,
  DescriptionContainer,
  WalletAddress,
  CardTitle,
  ViewOption
} from "./stakingstyles.js";
import { ButtonState } from "../reusables/button";

const StakingBalance = () => {
  return (
    <CardContainer>
      <CardTitle> WNT Staking Balance</CardTitle>
      <ViewOption>
        {" "}
        <i className="fa fa-eye-slash" /> Hide all numbers
      </ViewOption>
      <div className="col-lg-3 col-sm-3">
        <WalletAddress> sj7360x5bA6...54E1</WalletAddress>
      </div>

      <DescriptionContainer>
        <div>
          <Description> Total Staked</Description>
          <DescriptionValue>
            {" "}
            500WNT{" "}
            <DescriptionSubValue>
              {" "}
              <i className="fa fa-arrows-h" aria-hidden="true" />
              $300
            </DescriptionSubValue>{" "}
          </DescriptionValue>
        </div>

        <div>
          <Description> Total Rewared Estimated</Description>
          <DescriptionValue>
            {" "}
            500WNT{" "}
            <DescriptionSubValue>
              {" "}
              <i className="fa fa-arrows-h" aria-hidden="true" />
            </DescriptionSubValue>{" "}
          </DescriptionValue>
        </div>
      </DescriptionContainer>

      <div className="row mb-3">
        <ButtonState
          fontSize={"0.8rem"}
          buttonClass="primary"
          label={"Stake"}
        />
      </div>
      <div className="row ">
        <ButtonState
          fontSize={"0.8rem"}
          buttonClass="secondary"
          label={"UnStake"}
        />
      </div>
    </CardContainer>
  );
};

export default StakingBalance;
