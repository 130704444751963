import deviceCount from "./device-count";
import deviceDetails from "./device-details";
import _const from "../../actions/types";

function allDeviceCount() {
  return async (dispatch: Function) => {
    try {
      const details = await deviceDetails(2);
      dispatch({ type: _const.ALL_DEVICES, payload: details });
    } catch (err) {
      throw err;
    }
  };
}

export default allDeviceCount;
