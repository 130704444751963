import { combineReducers } from "redux";

import General from "./generalReducer";
import ConnectWalletReducer from "./ConnectWalletReducer";
import DeviceReducer from "./deviceReducer";
import LoaderReducer from "./loaderReducer";
import ShippingReducer from "./shippingReducer";

export default combineReducers({
  General,
  ConnectWalletReducer,
  DeviceReducer,
  LoaderReducer,
  ShippingReducer
});
