import erc20Abi from "./abiManager/erc20.json";
import web3Initiate from "../web3Initiate";

async function deviceDecimals(tokenAddress: String) {
  try {
    const contract = await web3Initiate(erc20Abi, tokenAddress);
    return await contract.methods.decimals().call();
  } catch (err) {
    console.log(err);
  }
}

export default deviceDecimals;
