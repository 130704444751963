import web3 from "web3";
import { ConnectorNames } from "./types";
import { connectorsByName } from "./web3React";
import { notify } from "../../reusables/notifier";
import _const from "../_const";
import removeAddress from "../utils/remove-address";
import { connectorLocalStorageKey } from "./config";
import reduxStore from "../reducers";
import { togglemodal } from "../actions";
import allDeviceCount from "../contracts/devices/all-device-count";
import deviceSum from "../contracts/devices/device-sum";
import { convertUtf8ToHex } from "@walletconnect/utils";
import { localDispatch } from "../actions/generalActions";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

export const login = (
  connectorID: ConnectorNames,
  chainId: number,
  walletName: string,
  deviceId: Number,
  tokenAddress: String
) => {
  return async (dispatch: Function) => {
    try {
      let account: any = null;
      const connector: any = connectorsByName(connectorID, chainId);
      const dt = { chainId, connectorID, walletName };

      localStorage.setItem("CONNECTION_DETAILS", JSON.stringify(dt));
      let signature = "";

      if (connector) {
        if (connectorID === "injected") {
          await switchOrAddNetworkToMetamask(chainId);

          let connection = await connector.activate();

          account = connection.account;
          window.APPWEB3 = new web3(web3.givenProvider);
          signature = await window.APPWEB3.eth.personal.sign(
            process.env.REACT_APP_SIGNATURE_MESSAGE,
            account
          );
        }

        if (connectorID === "walletconnect") {
          const result = await connector.enable();
          account = result[0];
          //very important 2 lines
          delete connector.__proto__.request;
          connector.hasOwnProperty("request") && delete connector.request;
          const provider: any = await new web3(connector as any);
          window.APPWEB3 = provider;

          const signconnector = new WalletConnect({
            bridge: "https://bridge.walletconnect.org", // Required
            qrcodeModal: QRCodeModal
          });

          const msgParams = [
            convertUtf8ToHex(process.env.REACT_APP_SIGNATURE_MESSAGE), // Required
            account // Required
          ];
          signature = await signconnector.signPersonalMessage(msgParams);
        }

        localStorage.setItem("APP_SIGNATURE", JSON.stringify(signature));

        if (account && signature) {
          console.log(signature, 'why do i have signature here')
          dispatch({
            type: _const.ADDRESS,
            payload: {
              address: account,
              walletInUse: walletName,
              chainId
            }
          });
          dispatch({
            type: _const.SIGNATURE,
            payload: signature
          });
          dispatch(togglemodal(false));
          dispatch(allDeviceCount());
          dispatch(deviceSum(deviceId, tokenAddress));
        }
      } else {
        console.warn("Can't find connector \n The connector config is wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const recreateWeb3 = (deviceId: Number, tokenAddress: String) => {
  return async (dispatch: Function) => {
    try {
      const connectionDetails = JSON.parse(
        localStorage.getItem("CONNECTION_DETAILS")
      );
      const signature = JSON.parse(localStorage.getItem("APP_SIGNATURE"));
      const mintProperties = JSON.parse(
        localStorage.getItem("MINT_PROPERTIES")
      );
      if (mintProperties) {
        dispatch(localDispatch(mintProperties));
      }

     
      if (connectionDetails && signature) {
        let account: any = null;

        let { walletName, chainId } = connectionDetails;
        dispatch({
          type: _const.ADDRESS,
          payload: { address: "", walletInUse: walletName, chainId }
        });

        const connector: any = connectorsByName(
          connectionDetails.connectorID,
          connectionDetails.chainId
        );
        if (connector) {
          if (connectionDetails.connectorID === "injected") {
            await switchOrAddNetworkToMetamask(connectionDetails.chainId);

            let connection = await connector.activate();

            connection.provider.on(
              "accountsChanged",
              (code: number, reason: string) => {
                const accountSwitch = code[0];
                if (accountSwitch) {
                  if (accountSwitch) {
                    dispatch({
                      type: _const.ADDRESS,
                      payload: { address: accountSwitch }
                    });
                  }
                } else {
                  DisconnectFromWallet();
                }
              }
            );

            account = connection.account;

            window.APPWEB3 = await new web3(web3.givenProvider);
          }

          if (connectionDetails.connectorID === "walletconnect") {
            const result = await connector.enable();
            account = result[0];

            // very important 2 lines
            delete connector.__proto__.request;
            connector.hasOwnProperty("request") && delete connector.request;

            const provider: any = await new web3(connector as any);

            window.APPWEB3 = provider;
          }

          if (account) {
            dispatch({
              type: _const.ADDRESS,
              payload: { address: account }
            });

            dispatch({
              type: _const.SIGNATURE,
              payload: signature
            });
            dispatch(allDeviceCount());
            if (mintProperties) {
              dispatch(
                deviceSum(
                  mintProperties.deviceId,
                  mintProperties.tokenAddress.address
                )
              );}
          }
        } else {
          console.warn("Can't find connector \n The connector config is wrong");
        }
      } else {
        console.log("Storage Data Not There Yet Show Modal");
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const DisconnectFromWallet = async () => {
  try {
    let connector: any = localStorage.getItem("CONNECTION_DETAILS");
    let { connectorID } = JSON.parse(connector);

    if (connectorID === "walletconnect") {
      localStorage.removeItem(connectorID);
    }

    removeAddress();

    window.sessionStorage.removeItem(connectorLocalStorageKey);
    window.sessionStorage.removeItem(_const.WEB3SETPROVIDER);
    window.sessionStorage.removeItem(
      _const.WEB3_WALLETCONNECT_HAS_DISCONNECTED
    );
    window.localStorage.removeItem(_const.NETWORK_PROVIDER_HAS_CHANGED);

    window.localStorage.removeItem("CONNECTION_DETAILS");

    const ConnectWalletReducerAction: any = await reduxStore();
    ConnectWalletReducerAction.dispatch({
      type: _const.PRISTINE
    });
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
};

async function switchOrAddNetworkToMetamask(chainId: number) {
  const hexChainId = `0x${chainId.toString(16)}`;
  try {
    if (window.ethereum) {
      // switch to the selected network
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexChainId }]
      });
    }
  } catch (e) {
    if (e.code === 4902) {
      let params: any = {};
      // add polygon
      if (chainId === 137) {
        params = {
          chainId: hexChainId,
          chainName: "Polygon Mainnet",
          nativeCurrency: {
            name: "MATIC",
            symbol: "matic",
            decimals: 18
          },

          rpcUrls: [process.env.REACT_APP_RPC_URL],
          blockExplorerUrls: ["https://explorer.matic.network/"]
        };
      }
      // add test polygon
      if (chainId === 80001) {
        params = {
          chainId: hexChainId,
          chainName: "Polygon Testnet",
          nativeCurrency: {
            name: "MATIC",
            symbol: "matic",
            decimals: 18
          },
          rpcUrls: [process.env.REACT_APP_RPC_URL],
          blockExplorerUrls: ["https://mumbai.polygonscan.com/"]
        };
      }

      try {
        // the network is added
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [params]
        });
      } catch (e) {
        notify("error", "Failed to add network to metamask");
      }
    }
  }
}
