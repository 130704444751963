import createContract from "../contract-creator";
import erc20Abi from "./abiManager/erc20.json";
import retrieveAddress from "../../retrieve-address";
import { toFixed } from "../../helper";
import web3Initiate from "../web3Initiate";
import getFastGasFeeMatic from "./gas-fee";

async function approveMint(
  price: String,
  quantity: number,
  tokenAddress: String
) {
  try {
    const ownerAddress = retrieveAddress();
    const paymentTokenContract = await createContract(erc20Abi, tokenAddress);
    let gasFee = await getFastGasFeeMatic();
    let total = Number(price) * quantity;
    let value = toFixed(total);
    let res = await paymentTokenContract.methods
      .approve(process.env.REACT_APP_NFT_CONTRACT, String(value))
      .send({ from: ownerAddress, gasPrice: gasFee });

    let response = await paymentTokenContract.methods
      .allowance(ownerAddress, process.env.REACT_APP_NFT_CONTRACT)
      .call();

    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export default approveMint;
