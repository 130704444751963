import deviceAbi from "./abiManager/nft-device.json";
import web3Initiate from "../web3Initiate";

async function devicePrice(deviceId: String, tokenAddress: String) {
  try {
    const contract = await web3Initiate(
      deviceAbi,
      process.env.REACT_APP_NFT_CONTRACT
    );
    return await contract.methods.getDevicePrice(deviceId, tokenAddress).call();
  } catch (err) {
    console.log(err);
  }
}

export default devicePrice;
