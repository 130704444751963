import styled from "styled-components";
import { device } from "../../theme";

export const StakingTitle = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.textSm};
  font-weight: ${({ theme }) => theme.textBold};
  color: ${({ theme }) => theme.textColor};
`;

export const StakingContainer = styled.div`
  padding: 20px;
`;
export const CardContainer = styled.div`
  padding: 20px;
  background: #2b2f36;
  box-shadow: 0px 0px 8.17112px rgba(45, 58, 58, 0.02);
  border-radius: 8.17112px;
  position: relative;
  margin-bottom: 10px;
`;
export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ViewOption = styled.p`
  font-size: ${({ theme }) => theme.textXXs};
  font-weight: ${({ theme }) => theme.textBold};
  color: ${({ theme }) => theme.highlight};
`;
export const CardTitle = styled.p`
  font-size: ${({ theme }) => theme.textXXs};
  font-weight: ${({ theme }) => theme.textBold};
  color: ${({ theme }) => theme.textColor};
`;
export const Description = styled.div`
  color: ${({ theme }) => theme.grey2};
  font-weight: ${({ theme }) => theme.textBold};
  font-size: ${({ theme }) => theme.textXXs};
`;
export const DescriptionValue = styled.p`
  color: ${({ theme }) => theme.textColor};
  font-size: ${({ theme }) => theme.textXs};
  font-weight: ${({ theme }) => theme.textBold};
`;
export const DescriptionSubValue = styled.span`
  color: ${({ theme }) => theme.grey2};
  font-size: ${({ theme }) => theme.textXXs};
`;
export const WalletAddress = styled.div`
  background: ${({ theme }) => theme.mainColor};
  color: ${({ theme }) => theme.grey2};
  font-size: 0.6rem;
  padding: 7px;
  border-radius: 18.1224px;
  margin-bottom: 10px;
  width: 35%;
`;
