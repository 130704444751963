import { useSelector } from "react-redux";
import {
  CommunityContainer,
  CommunityTitle,
  SocialsName,
  SocialsContainer,
  IconContainer,
  IconText,
  CommunityOption,
} from "./homestyles";

const Community = () => {
  const theme = useSelector((state) => state.General.theme);
  const communityIcons = useSelector((state) => state.General.communityIcons);
  return (
    <CommunityContainer>
      <CommunityTitle> Join the community</CommunityTitle>
      <SocialsContainer>
        {communityIcons.map((each, i) => {
          return (
            <CommunityOption key={i}>
              <IconText href={each.link} target="_blank">
                <IconContainer>
                  <img src={!theme ? each.light : each.dark} />
                </IconContainer>
                <SocialsName>{each.name}</SocialsName>
              </IconText>
            </CommunityOption>
          );
        })}
      </SocialsContainer>
    </CommunityContainer>
  );
};

export default Community;
