import {
  Back,
  ShippingCard,
  DetailHeading,
  BreadCrumbs,
  BreadCrumbPresent,
  BreadCrumbParent,
  InfoTitle,
  InfoValue,
  InfoHolder,
  LeftInfo,
  RightInfo,
  EditButton,
} from "./style";
import { ButtonState } from "../reusables/button";
import { useDispatch, useSelector } from "react-redux";
import { getFormDetails } from "../methods/actions";
import mintWicryptDevice from "../methods/actions/mintActions";
import { Link } from "react-router-dom";
import Loader from "../reusables/loader";
import Success from "../success";

const ShippingDetail = () => {
  const dispatch = useDispatch();

  const streetaddress = useSelector((state) => state.General.streetaddress);

  const suitnumber = useSelector((state) => state.General.suitnumber);

  const country = useSelector((state) => state.General.country);

  const state = useSelector((state) => state.General.state);

  const zipcode = useSelector((state) => state.General.zipcode);

  const city = useSelector((state) => state.General.city);

  const emailAddress = useSelector((state) => state.General.emailAddress);

  const phoneNumber = useSelector((state) => state.General.phoneNumber);

  //const referralCode = useSelector(state => state.General.referralCode);
  const status = useSelector((state) => state.General.status);

  const realPrice = useSelector((state) => state.General.realPrice);
  const quantity = useSelector((state) => state.General.quantity);
  const deviceId = useSelector((state) => state.General.selectedDeviceId);
  const tokenAddress = useSelector((state) => state.General.tokenAddress);
  const longitude = useSelector((state) => state.General.longitude);
  const latitude = useSelector((state) => state.General.latitude);
  const approvingMint = useSelector(
    (store) => store.LoaderReducer.approvingMint
  );
  const firstName = useSelector((state) => state.General.firstName);
  const lastName = useSelector((state) => state.General.lastName);

  return (
    <>
      {" "}
      {status ? (
        <Success />
      ) : (
        <div className="comtainer">
          <div className="col-lg-12">
            <div className="col-lg-10 offset-lg-1">
              <Link to="/" className="link-decoration">
                <Back>
                  {" "}
                  <i
                    className="fa fa-arrow-left mr-1"
                    aria-hidden="true"
                  />{" "}
                  Back to Home
                </Back>
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="col-lg-6 col-sm-12 col-xs-12 offset-lg-3">
              <BreadCrumbs>
                {" "}
                <BreadCrumbParent> Shipping </BreadCrumbParent>{" "}
                <BreadCrumbParent>
                  <i className="fa fa-chevron-right" aria-hidden="true" />
                </BreadCrumbParent>
                <BreadCrumbPresent> Summary</BreadCrumbPresent>
              </BreadCrumbs>
              <ShippingCard>
                <InfoHolder>
                  <DetailHeading> Shipping Details</DetailHeading>
                  <EditButton
                    className="btn"
                    onClick={() =>
                      dispatch(
                        getFormDetails({
                          props: ["shippingsaved"],
                          value: false,
                        })
                      )
                    }
                  >
                    {" "}
                    Edit
                  </EditButton>
                </InfoHolder>
                <InfoHolder>
                  <LeftInfo>
                    <InfoTitle> First Name</InfoTitle>
                    <InfoValue>{firstName}</InfoValue>
                  </LeftInfo>
                  <RightInfo>
                    <InfoTitle> Last Name</InfoTitle>
                    <InfoValue>{lastName}</InfoValue>
                  </RightInfo>
                </InfoHolder>
                <InfoHolder>
                  <LeftInfo>
                    <InfoTitle> Street</InfoTitle>
                    <InfoValue>{streetaddress}</InfoValue>
                  </LeftInfo>
                  <RightInfo>
                    <InfoTitle> Apartment / Suit no</InfoTitle>
                    <InfoValue>{suitnumber}</InfoValue>
                  </RightInfo>
                </InfoHolder>
                <InfoHolder>
                  <LeftInfo>
                    <InfoTitle> Country</InfoTitle>
                    <InfoValue>{country}</InfoValue>
                  </LeftInfo>
                  <RightInfo>
                    <InfoTitle> State</InfoTitle>
                    <InfoValue>{state}</InfoValue>
                  </RightInfo>
                </InfoHolder>
                <InfoHolder>
                  <LeftInfo>
                    <InfoTitle> Postal Code</InfoTitle>
                    <InfoValue>{zipcode}</InfoValue>
                  </LeftInfo>
                  <RightInfo>
                    <InfoTitle> City</InfoTitle>
                    <InfoValue>{city}</InfoValue>
                  </RightInfo>
                </InfoHolder>
                <InfoHolder>
                  <LeftInfo>
                    <InfoTitle> Email address</InfoTitle>
                    <InfoValue>{emailAddress}</InfoValue>
                  </LeftInfo>
                  <RightInfo>
                    <InfoTitle>Phone Number</InfoTitle>
                    <InfoValue>{phoneNumber}</InfoValue>
                  </RightInfo>
                </InfoHolder>
              </ShippingCard>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="t-center mt-5">
              <ButtonState
                disabled={approvingMint}
                buttonClass="primary"
                onClick={() =>
                  dispatch(
                    mintWicryptDevice(
                      deviceId,
                      quantity,
                      tokenAddress.address,
                      longitude,
                      latitude,
                      realPrice
                    )
                  )
                }
                label={approvingMint ? <Loader /> : "Confirm"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShippingDetail;
