import {
  DescriptionContainer,
  AboutUs,
  Title,
  Description,
  WicryptDevices,
  OutlierContainer1,
  OutlierContainer2,
  DeviceImg,
} from "./homestyles";

const About = () => {
  return (
    <DescriptionContainer data-testid="about">
      <AboutUs>
        {" "}
        <Title> About Outliers</Title>
        <Description>
          The Outliers are rebels, round pegs in square holes, they do not
          respect the status quo, they are geniuses, they are different. For
          years they have watched how big telecommunication organizations and
          governments have controlled the global internet system, enriching
          their pockets by charging high rates, providing limited connectivity
          globally causing billions of world citizens not to be connected to the
          internet. As the world advances in technology the Outliers have seen a
          rise in IoTs and these billions of devices will also need to be
          connected to the internet. The Outliers are building a decentralized
          internet sharing and monetization network that will connect humans and
          IoTs. This smart network will be powered by you, our next Outlier.
        </Description>
      </AboutUs>
      <WicryptDevices>
        <OutlierContainer1>
          <DeviceImg src="./assets/lynx.svg" alt="lynx" />
        </OutlierContainer1>
        <OutlierContainer2>
          <DeviceImg src="./assets/spider.svg" alt="spider" />
        </OutlierContainer2>
      </WicryptDevices>
    </DescriptionContainer>
  );
};

export default About;
