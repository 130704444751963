import { useEffect } from "react";
import {
  Back,
  ShippingCard,
  DetailHeading,
  InputBox,
  FlexInputs,
  BreadCrumbs,
  BreadCrumbPresent,
  BreadCrumbParent,
  DropDownContent,
  DropDownItem,
  DropDownArrow,
  CenterLoader,
  ErrMessage,
  ShippingPage,
} from "./style";
import { ButtonState } from "../reusables/button";
import { useDispatch, useSelector } from "react-redux";
import {
  saveShipmentDetail,
  getFormDetails,
  getShipmentDetail,
} from "../methods/actions";
import { Link } from "react-router-dom";
import Loader from "../reusables/loader";
import Success from "../success";
import ShippingDetail from "./shippingdetail";
import NavBar from "../../components/partials/navbar";

const Shipping = () => {
  const dispatch = useDispatch();

  const sendingShipment = useSelector(
    (state) => state.LoaderReducer.sendingShipment
  );
  const countryList = useSelector((state) => state.General.countryList);

  const streetaddress = useSelector((state) => state.General.streetaddress);

  const suitnumber = useSelector((state) => state.General.suitnumber);

  const country = useSelector((state) => state.General.country);

  const state = useSelector((state) => state.General.state);

  const zipcode = useSelector((state) => state.General.zipcode);

  const city = useSelector((state) => state.General.city);

  const emailAddress = useSelector((state) => state.General.emailAddress);

  const phoneNumber = useSelector((state) => state.General.phoneNumber);

  const referralCode = useSelector((state) => state.General.referralCode);
  const status = useSelector((state) => state.General.status);

  const signature = useSelector(
    (state) => state.ConnectWalletReducer.signature
  );
  const realPrice = useSelector((state) => state.General.realPrice);
  const quantity = useSelector((state) => state.General.quantity);
  const deviceId = useSelector((state) => state.General.selectedDeviceId);
  const tokenAddress = useSelector((state) => state.General.tokenAddress);
  const longitude = useSelector((state) => state.General.longitude);
  const latitude = useSelector((state) => state.General.latitude);
  const approvingMint = useSelector(
    (store) => store.LoaderReducer.approvingMint
  );
  const dropdown = useSelector((state) => state.General.dropdown);
  const shippingsaved = useSelector((state) => state.General.shippingsaved);
  const firstName = useSelector((state) => state.General.firstName);
  const lastName = useSelector((state) => state.General.lastName);
  const gettingShippingInfo = useSelector(
    (store) => store.LoaderReducer.gettingShippingInfo
  );
  const shippingInfoErr = useSelector(
    (state) => state.ShippingReducer.shippingInfoErr
  );

  useEffect(() => {
    dispatch(getShipmentDetail());
  }, []);

  return (
    <ShippingPage>
      <NavBar />{" "}
      {status ? (
        <Success />
      ) : gettingShippingInfo ? (
        <CenterLoader>
          <Loader color={"#E5B910"} size={20} />
        </CenterLoader>
      ) : shippingsaved ? (
        <ShippingDetail />
      ) : (
        <div className="comtainer">
          <div className="col-lg-12">
            <div className="col-lg-10 offset-lg-1">
              <Link to="/" className="link-decoration">
                <Back>
                  {" "}
                  <i
                    className="fa fa-arrow-left mr-1"
                    aria-hidden="true"
                  />{" "}
                  Back to Home
                </Back>
              </Link>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="col-lg-6 col-sm-12 col-xs-12 offset-lg-3">
              <BreadCrumbs>
                {" "}
                <BreadCrumbParent> Shipping </BreadCrumbParent>{" "}
                <BreadCrumbParent>
                  <i className="fa fa-chevron-right" aria-hidden="true" />
                </BreadCrumbParent>
                <BreadCrumbPresent> Summary</BreadCrumbPresent>
              </BreadCrumbs>
              <ShippingCard>
                <DetailHeading> Shipping Summary</DetailHeading>

                <FlexInputs>
                  <InputBox
                    className="form-control mr-1"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["firstName"],
                          value: e.target.value,
                        })
                      )
                    }
                  />
                  <InputBox
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["lastName"],
                          value: e.target.value,
                        })
                      )
                    }
                  />
                </FlexInputs>

                <InputBox
                  value={streetaddress}
                  onChange={(e) =>
                    dispatch(
                      getFormDetails({
                        props: ["streetaddress"],
                        value: e.target.value,
                      })
                    )
                  }
                  className="form-control"
                  placeholder="Street address"
                />
                <InputBox
                  className="form-control"
                  placeholder="Apartment / Suit no"
                  value={suitnumber}
                  onChange={(e) =>
                    dispatch(
                      getFormDetails({
                        props: ["suitnumber"],
                        value: e.target.value,
                      })
                    )
                  }
                />
                <FlexInputs>
                  <div
                    style={{ position: "relative", width: "49%" }}
                    onClick={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["dropdown"],
                          value: !dropdown,
                        })
                      )
                    }
                  >
                    <InputBox
                      className="form-control mr-1"
                      placeholder="Country"
                      disabled
                      value={country}
                    />
                    {dropdown ? (
                      <DropDownArrow
                        className="fa fa-chevron-up"
                        aria-hidden="true"
                      />
                    ) : (
                      <DropDownArrow
                        className="fa fa-chevron-down"
                        aria-hidden="true"
                      />
                    )}

                    {dropdown ? (
                      <DropDownContent>
                        {countryList.map((each, i) => {
                          return (
                            <DropDownItem
                              key={i}
                              onClick={(e) =>
                                dispatch(
                                  getFormDetails({
                                    props: ["country"],
                                    value: each,
                                  })
                                )
                              }
                            >
                              {" "}
                              {each}
                            </DropDownItem>
                          );
                        })}
                      </DropDownContent>
                    ) : (
                      ""
                    )}
                  </div>

                  <div style={{ position: "relative", width: "49%" }}>
                    <InputBox
                      className="form-control"
                      placeholder="State"
                      value={state}
                      onChange={(e) =>
                        dispatch(
                          getFormDetails({
                            props: ["state"],
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  </div>
                </FlexInputs>
                <FlexInputs>
                  <InputBox
                    className="form-control mr-1"
                    placeholder="Postal Code"
                    value={zipcode}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["zipcode"],
                          value: e.target.value,
                        })
                      )
                    }
                  />
                  <InputBox
                    className="form-control"
                    placeholder="City"
                    value={city}
                    onChange={(e) =>
                      dispatch(
                        getFormDetails({
                          props: ["city"],
                          value: e.target.value,
                        })
                      )
                    }
                  />
                </FlexInputs>
                <InputBox
                  className="form-control"
                  placeholder="Email address"
                  value={emailAddress}
                  onChange={(e) =>
                    dispatch(
                      getFormDetails({
                        props: ["emailAddress"],
                        value: e.target.value,
                      })
                    )
                  }
                />
                <InputBox
                  className="form-control"
                  placeholder="Phone number +234"
                  value={phoneNumber}
                  onChange={(e) =>
                    dispatch(
                      getFormDetails({
                        props: ["phoneNumber"],
                        value: e.target.value,
                      })
                    )
                  }
                />
                <InputBox
                  className="form-control"
                  placeholder="Referral Code (optional)"
                  value={referralCode}
                  onChange={(e) =>
                    dispatch(
                      getFormDetails({
                        props: ["referralCode"],
                        value: e.target.value,
                      })
                    )
                  }
                />
                <ErrMessage> {shippingInfoErr}</ErrMessage>
              </ShippingCard>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="t-center mt-5">
              <ButtonState
                disabled={approvingMint}
                buttonClass="primary"
                onClick={() =>
                  dispatch(
                    saveShipmentDetail(
                      streetaddress,
                      suitnumber,
                      country,
                      state,
                      zipcode,
                      city,
                      emailAddress,
                      phoneNumber,
                      referralCode,
                      signature,
                      deviceId,
                      quantity,
                      tokenAddress.address,
                      longitude,
                      latitude,
                      realPrice,
                      firstName,
                      lastName
                    )
                  )
                }
                label={sendingShipment || approvingMint ? <Loader /> : "Mint"}
              />
            </div>
          </div>
        </div>
      )}
    </ShippingPage>
  );
};

export default Shipping;
