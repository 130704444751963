import { useState } from "react";
import About from "./about";
import MintSection from "./mintsection";
import Community from "./community";
import { SuccessModal } from "../reusables/succesModal";
import { WishListModal } from "../reusables/wishListModal";
import Announcements from "./announcement";
import AlternateNavBar from "../../components/partials/alternatenav";
import Other from "./other";
import Footer from "../../components/partials/footer";
import { Section, CommunitySection, HeaderSection } from "./homestyles";

const Body = () => {
  const [modal, toggleModal] = useState(false);

  const [successModal, showSuccessModal] = useState(false);

  const closeModals = () => {
    showSuccessModal(false);
  };

  return (
    <>
      <HeaderSection>
        <AlternateNavBar />
        {successModal ? <SuccessModal onClick={closeModals} /> : null}
        {modal ? (
          <WishListModal
            toggleModal={toggleModal}
            showSuccessModal={showSuccessModal}
          />
        ) : null}
        {/* <Landing onClick={() => toggleModal(true)} /> */}

        <MintSection onClick={() => toggleModal(true)} />
      </HeaderSection>
      <Section>
        <Announcements />
      </Section>
      <Section>
        <About />
      </Section>
      <Section>
        <Other />
      </Section>
      <CommunitySection>
        <Community />
      </CommunitySection>
      <Section>
        <Footer />
      </Section>
    </>
  );
};

export default Body;
