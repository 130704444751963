import _const from "../actions/types";

const initialState = {
  shippingInfo: {},
  shippingInfoErr: "",
  tokenPrice: 0,
};

const ShippingReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.USER_SHIPMENT_INFO:
      return { ...state, shippingInfo: action.payload };

    case _const.SHIPPING_INFO_ERR:
      return { ...state, shippingInfoErr: action.payload };

    case _const.MARKET_QUOTE:
      return { ...state, tokenPrice: action.payload.usdQuote };

    default:
      return state;
  }
};

export default ShippingReducer;
