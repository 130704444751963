import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MintContainer,
  MintDevice,
  MintSubTitle,
  Button,
  MintTitle,
  MintValue,
  MintSubValue,
  MintCardBody,
  Underline,
  MintCard,
  InputBox,
  ActionBox,
  MintQuantity,
  MintTotalSection,
  MintRow,
  MintHeader,
  CardContainer,
  SideIcon,
  RightIcon,
  // MintDeviceIntro,
  MintCalculated,
  DeviceBox,
  NotificationText,
  NotConnectedCardBody,
  Error,
  SelectCurrenctBox,
  Currency,
  CurrencyValue,
  DeviceContainer,
  DeviceNamelabel,
  Dropdown,
  // VisitProductStageContainer,
  AdContainer,
  // Note,
  BlurryIcon,
  MintCardContainer,
  ProgressContainer,
  CurrencyContainer,
  TokenSymbol,
  CurrencySymbol,
  QuantityActionBox,
  TermaandConditions,
  RadioButton,
} from "./homestyles";
import { Link } from "react-router-dom";
import { getFormDetails } from "../methods/actions/generalActions";
import deviceSum from "../methods/contracts/devices/device-sum";
import ConnectWallet from "../wallets/connectwallet";
import Loader from "../reusables/loader";

const MintSection = ({ onClick }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.General.theme);
  const activeDevice = useSelector((state) => state.General.activeDevice);
  const formattedPrice = useSelector((state) => state.General.formattedPrice);
  const alldevices = useSelector((state) => state.DeviceReducer.alldevices);
  const quantity = useSelector((state) => state.General.quantity);
  const deviceId = useSelector((state) => state.General.selectedDeviceId);
  const tokenAddress = useSelector((state) => state.General.tokenAddress);
  const termsandconditions = useSelector(
    (state) => state.General.termsandconditions
  );
  const address = useSelector((store) => store.ConnectWalletReducer.address);
  const approvingMint = useSelector(
    (store) => store.LoaderReducer.approvingMint
  );
  const mintError = useSelector((state) => state.General.mintError);
  const token = useSelector((state) => state.General.token);
  const showlist = useSelector((state) => state.General.showlist);

  const getLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          dispatch(
            getFormDetails({
              props: ["longitude"],
              value: position.coords.longitude,
            })
          );
          dispatch(
            getFormDetails({
              props: ["latitude"],
              value: position.coords.latitude,
            })
          );
        },
        (error) => {
          console.log(error, "i am err");
        }
      );
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    dispatch(deviceSum(activeDevice + 1, tokenAddress.address));
  }, [tokenAddress.address, activeDevice]);

  const handleLeftSlide = (activeDevice, deviceId) => {
    if (activeDevice + 1 !== 1) {
      dispatch(
        getFormDetails({
          props: ["activeDevice"],
          value: activeDevice - 1,
        })
      );
      dispatch(
        getFormDetails({
          props: ["selectedDeviceId"],
          value: deviceId - 1,
        })
      );
      dispatch(deviceSum(deviceId - 1, tokenAddress.address));
    }
  };

  const handleRightSlide = (alldevices, activeDevice, deviceId) => {
    if (alldevices.length > activeDevice + 1) {
      dispatch(
        getFormDetails({
          props: ["activeDevice"],
          value: activeDevice + 1,
        })
      );
      dispatch(
        getFormDetails({
          props: ["selectedDeviceId"],
          value: deviceId + 1,
        })
      );
      dispatch(deviceSum(deviceId + 1, tokenAddress.address));
    }
  };

  const handleDeviceSelect = (activeDevice, deviceId) => {
    dispatch(
      getFormDetails({
        props: ["activeDevice"],
        value: activeDevice,
      })
    );
    dispatch(
      getFormDetails({
        props: ["selectedDeviceId"],
        value: activeDevice + 1,
      })
    );
    dispatch(deviceSum(activeDevice + 1, tokenAddress.address));
  };

  const handleReduceQuantity = (quantity) => {
    if (quantity > 1) {
      dispatch(
        getFormDetails({
          props: ["quantity"],
          value: Number(quantity) - 1,
        })
      );
    }
  };

  return (
    <MintContainer data-testid="mint-section">
      <SideIcon>
        <img src="./assets/minticon.svg" alt="mint-icon" />
      </SideIcon>
      <BlurryIcon>
        <img src="./assets/blurrycoin.svg" alt="blurry-mint-icon" />
      </BlurryIcon>
      <RightIcon>
        <img src="./assets/rightmint.svg" alt="mint-icon" />
      </RightIcon>
      <CardContainer>
        <MintDevice> Mint your Wicrypt devices as NFT</MintDevice>
        <MintSubTitle>
          Get even more value for your Wicrypt device when you mint it as an
          NFT. Join our exclusive community of Wicrypt Outliers
        </MintSubTitle>
        <a
          href="https://wicrypt.com/product.html"
          rel="noreferrer"
          target="_blank"
        >
          {" "}
          <Button> View Product</Button>
        </a>
      </CardContainer>

      <MintCardContainer>
        {address ? (
          <MintCard>
            <MintCardBody>
              <MintHeader>
                <ProgressContainer>
                  <MintSubValue>
                    {" "}
                    {alldevices.length > 0 ? alldevices[activeDevice][0] : ""}
                  </MintSubValue>
                  <progress
                    id="file"
                    value={
                      alldevices.length > 0 ? alldevices[activeDevice][2] : 0
                    }
                    max={
                      alldevices.length > 0 ? alldevices[activeDevice][3] : 0
                    }
                  >
                    {" "}
                    {"32"}
                  </progress>
                  <MintValue>
                    {" "}
                    {alldevices.length > 0
                      ? alldevices[activeDevice][2]
                      : 0}/{" "}
                    {alldevices.length > 0 ? alldevices[activeDevice][3] : 0}{" "}
                    Minted
                  </MintValue>
                </ProgressContainer>
                <CurrencyContainer>
                  <MintSubValue>Select currency</MintSubValue>
                  <SelectCurrenctBox
                    onClick={() =>
                      dispatch(
                        getFormDetails({
                          props: ["showlist"],
                          value: !showlist,
                        })
                      )
                    }
                  >
                    <CurrencySymbol>
                      <img
                        src={tokenAddress.tokenIcon}
                        height={20}
                        alt="coin-logo"
                      />
                      <TokenSymbol> {tokenAddress.tokenSymbol}</TokenSymbol>
                    </CurrencySymbol>
                    <div style={{ position: "relative" }}>
                      <CurrencyValue>
                        {" "}
                        {!Number.isNaN(formattedPrice)
                          ? formattedPrice
                          : 0}{" "}
                      </CurrencyValue>
                      {!theme ? (
                        <img
                          src="/assets/lightcarretdown.svg"
                          alt="coin-logo"
                        />
                      ) : (
                        <img
                          src="/assets/lightcarretdown.svg"
                          alt="coin-logo"
                        />
                      )}
                    </div>
                  </SelectCurrenctBox>
                </CurrencyContainer>
              </MintHeader>
              <div>
                {tokenAddress.tokenSymbol === "WNT" ? (
                  <AdContainer>
                    {" "}
                    Download the
                    <a
                      href="https://medium.com/wicrypt/how-to-purchase-wnt-matic-from-mexc-exchange-and-send-to-a-metamask-wallet-12cd0df82c5a?postPublishedType=repub"
                      target="_blank"
                    >
                      {" "}
                      Wicrypt mobile app
                    </a>{" "}
                    to purchase a device or buy WNT via
                    <a
                      href="https://medium.com/wicrypt/how-to-purchase-wnt-matic-from-mexc-exchange-and-send-to-a-metamask-wallet-12cd0df82c5a?postPublishedType=repub"
                      target="_blank"
                    >
                      {" "}
                      Xend Bridge{" "}
                    </a>
                  </AdContainer>
                ) : null}
              </div>
              {showlist ? (
                <Dropdown>
                  {token.map((each, i) => {
                    return (
                      <SelectCurrenctBox
                        key={i}
                        onClick={() => {
                          dispatch(
                            getFormDetails({
                              props: ["tokenAddress"],
                              value: each,
                            })
                          );
                          dispatch(
                            getFormDetails({
                              props: ["showlist"],
                              value: !showlist,
                            })
                          );
                        }}
                      >
                        <div>
                          <img
                            src={each.tokenIcon}
                            height={20}
                            alt="coin-logo"
                          />
                          <Currency> </Currency>
                        </div>
                        <div>
                          <CurrencyValue>{each.tokenSymbol}</CurrencyValue>
                        </div>
                      </SelectCurrenctBox>
                    );
                  })}
                </Dropdown>
              ) : (
                ""
              )}
              <Underline></Underline>
              <div>
                <MintTitle> Select device</MintTitle>
              </div>
              <MintRow>
                <div>
                  <ActionBox
                    active={activeDevice + 1 !== 1 ? true : false}
                    onClick={() => handleLeftSlide(activeDevice, deviceId)}
                  >
                    {" "}
                    {"<"}
                  </ActionBox>
                </div>
                {alldevices.map((each, i) => {
                  return (
                    <DeviceContainer
                      active={i === activeDevice ? true : false}
                      key={i}
                      onClick={() => handleDeviceSelect(i, deviceId)}
                    >
                      <DeviceBox active={i === activeDevice ? true : false}>
                        <img
                          src={
                            i === 0
                              ? "/assets/lynx2.svg"
                              : "./assets/spider2.svg"
                          }
                          className="img-responsive"
                          alt={"device" + i}
                        />
                      </DeviceBox>
                      <DeviceNamelabel>
                        {alldevices.length > 0 ? alldevices[i][0] : ""}
                      </DeviceNamelabel>
                    </DeviceContainer>
                  );
                })}
                <div>
                  <ActionBox
                    active={alldevices.length > activeDevice + 1 ? true : false}
                    onClick={() =>
                      handleRightSlide(alldevices, activeDevice, deviceId)
                    }
                  >
                    {" "}
                    {">"}
                  </ActionBox>
                </div>
              </MintRow>
              <MintTotalSection>
                <div className="row">
                  <div className="col-12">
                    <div className="offset-lg-3  offset-sm-2 col-lg-6 col-sm-9 col-xs-9 ">
                      <MintSubValue> QTY </MintSubValue>
                      <MintQuantity>
                        <QuantityActionBox
                          onClick={() => handleReduceQuantity(quantity)}
                          active={false}
                        >
                          {" "}
                          -
                        </QuantityActionBox>
                        <InputBox
                          onChange={(e) =>
                            dispatch(
                              getFormDetails({
                                props: ["quantity"],
                                value: e.target.value,
                              })
                            )
                          }
                          value={quantity}
                        />
                        <QuantityActionBox
                          active={true}
                          onClick={() =>
                            dispatch(
                              getFormDetails({
                                props: ["quantity"],
                                value: Number(quantity) + 1,
                              })
                            )
                          }
                        >
                          {" "}
                          +{" "}
                        </QuantityActionBox>
                      </MintQuantity>
                    </div>
                  </div>
                </div>

                <MintSubValue> Total Cost Price</MintSubValue>
                <MintCalculated>
                  {!Number.isNaN(formattedPrice)
                    ? quantity * formattedPrice
                    : 0}{" "}
                  {tokenAddress.tokenSymbol}
                </MintCalculated>
                <div className="row">
                  <div className="col-lg-12">
                    <TermaandConditions>
                      <RadioButton
                        onChange={() =>
                          dispatch(
                            getFormDetails({
                              props: ["termsandconditions"],
                              value: !termsandconditions,
                            })
                          )
                        }
                        type="checkbox"
                        checked={termsandconditions}
                      />
                      I agree to{" "}
                      <a
                        href="https://wicrypt.com/terms-condition.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Wicrypt's terms & conditions
                      </a>{" "}
                    </TermaandConditions>
                  </div>
                </div>
                <Error> {mintError}</Error>
                {termsandconditions ? (
                  <Link
                    to="/shipping"
                    onClick={() =>
                      localStorage.setItem(
                        "MINT_PROPERTIES",
                        JSON.stringify({
                          quantity,
                          deviceId,
                          tokenAddress,
                          activeDevice,
                        })
                      )
                    }
                  >
                    <Button>
                      {approvingMint ? <Loader /> : "Mint Device "}
                    </Button>
                  </Link>
                ) : (
                  <Button disabled>Mint Device</Button>
                )}
              </MintTotalSection>
            </MintCardBody>
          </MintCard>
        ) : (
          <MintCard className="card">
            <MintCardBody className="card-body">
              <NotConnectedCardBody>
                {theme ? (
                  <embed
                    width="50%"
                    src="/assets/walletAnimationDark.gif"
                    alt="location-icon"
                  />
                ) : (
                  <embed
                    width="50%"
                    src="/assets/walletAnimationLight.gif"
                    alt="location-icon"
                  />
                )}
              </NotConnectedCardBody>
              <NotificationText>
                Connect your wallet first to mint a device
              </NotificationText>
              <NotConnectedCardBody className="mb-4">
                <ConnectWallet />
              </NotConnectedCardBody>
            </MintCardBody>
          </MintCard>
        )}
      </MintCardContainer>
    </MintContainer>
  );
};

export default MintSection;
