const _const = {
  // General actions
  TOGGLE_THEME: "TOGGLE_THEME",
  TOGGLE_MODAL: "TOGGLE_MODAL",
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  TOGGLE_BUTTON: "TOGGLE_BUTTON",
  NO_THEME_CHANGE: "NO_THEME_CHANGE",

  //Devices
  ALL_DEVICES: "ALL_DEVICES",
  MINTED_DEVICES: "MINTED_DEVICES",
  DEVICE_PRICE: "DEVICE_PRICE",

  //Form Inputs
  FILL_OUT_FORM: "FILL_OUT_FORM",

  //Mint
  SUCCESSFUL_APPROVE_MINT: "SUCCESSFUL_APPROVE_MINT",
  FAILED_APPROVE_MINT: "FAILED_APPROVE_MINT",
  APPROVING_MINT: "APPROVING_MINT",

  //Clear object
  CLEAR_DATA: "CLEAR_DATA",

  //SHIPMENT
  SENDING_SHIPMENT_INFO: "SENDING_SHIPMENT_INFO",
  GETTING_SHIPMENT_INFO: "GETTING_SHIPMENT_INFO",
  USER_SHIPMENT_INFO: "USER_SHIPMENT_INFO",
  SHIPPING_INFO_ERR: "SHIPPING_INFO_ERR",

  MINT_PROPERTIES: "MINT_PROPERTIES",

  //Market
  MARKET_QUOTE: "MARKET_QUOTE",
};
export default _const;
