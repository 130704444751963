import Body from "./body";

const index = () => {
  return (
    <>
      <Body />
    </>
  );
};

export default index;
