import styled from "styled-components";
//import { device } from "../../theme";

export const DeviceBox = styled.div`
  // border: 2px solid #e5b910;
  // box-sizing: border-box;
  // border-radius: 15px;
  // padding: 10px;
  // transform: "scale(1.2, 1.2)";
  // margin-bottom: 10px;

  border: ${(props) =>
    props.active ? "2.85057px solid #e5b910" : "2.85057px solid #E5E5E5"};
  box-sizing: border-box;
  border-radius: 21.3793px;
  padding: 18px;
  transform: ${(props) => (props.active ? "scale(1, 1)" : "scale(0.6, 0.6)")};
  background-image: linear-gradient(
    180deg,
    rgba(26, 26, 26, 0) 0%,
    rgba(26, 26, 26, 0) 54.29%,
    rgba(26, 26, 26, 0.8) 100%
  );
  width: 40%;
`;

export const PurchaseSummary = styled.div`
  background: ${({ theme }) => theme.summarybox};
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
`;

export const SummaryDetail = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
  margin-bottom: 0px;
  border-bottom: ${(props) =>
    props.active ? ` 0.5px solid ${props.theme.grey}` : "none"};
`;

export const DetailTitle = styled.p`
  color: ${({ theme }) => theme.flexiblegrey};
  cursor: pointer;
`;

export const DeviceValue = styled.p`
  color: ${({ theme }) => theme.flexiblegrey};
  font-weight: bold;
`;

export const DetailHeading = styled.p`
  color: ${({ theme }) => theme.cardBlue};
  font-weight: bold;
  font-size: ${({ theme }) => theme.textXs};
`;

export const Back = styled.p`
  color: ${({ theme }) => theme.highlight};
  font-weight: bold;
  font-size: ${({ theme }) => theme.textXXs} !important;
  cursor: pointer;
`;

export const DeviceCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const DisapapearingDetail = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;
