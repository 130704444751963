import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMarketQuote } from "../methods/actions";
import {
  SmallText,
  FooterContainer,
  CopyRight,
  LinkPages,
  ExternalLinks,
  TokenContainer,
  TokenTitle,
  TokenPrice,
  DFlex,
  FooterLogoContainer,
  AvailableOn,
  PriceSection,
} from "./partialstyles";

const Footer = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.General.theme);
  const tokenPrice = useSelector((state) => state.ShippingReducer.tokenPrice);

  useEffect(() => {
    dispatch(getMarketQuote());
  }, [dispatch]);

  return (
    <FooterContainer>
      <FooterLogoContainer>
        <a href="https://nft.wicrypt.com/" rel="noreferrer" target="_blank">
          {" "}
          {theme ? (
            <img src="/assets/logodark.svg" alt="logo" />
          ) : (
            <img src="/assets/logolight.svg" alt="logo" />
          )}
        </a>{" "}
      </FooterLogoContainer>
      <ExternalLinks>
        <LinkPages
          href="https://stake.wicrypt.com/"
          target="_blank"
          rel="noreferrer"
        >
          Staking
        </LinkPages>
        <LinkPages
          href="https://wicrypt.com/faq.html"
          target="_blank"
          rel="noreferrer"
        >
          FAQs
        </LinkPages>
        <LinkPages
          href="http://stage.wicrypt.com/redoc/public"
          target="_blank"
          rel="noreferrer"
        >
          Documentation
        </LinkPages>
        <LinkPages
          href="https://scan.wicrypt.com/"
          target="_blank"
          rel="noreferrer"
        >
          Explorer
        </LinkPages>
        <LinkPages
          href="https://helpcenter.wicrypt.com/"
          target="_blank"
          rel="noreferrer"
        >
          Help Center
        </LinkPages>
        <LinkPages
          href="https://api.wicrypt.com/"
          target="_blank"
          rel="noreferrer"
        >
          API
        </LinkPages>
        {/* <LinkPages>Hotspot devices</LinkPages> */}
        <LinkPages
          href="http://stage.wicrypt.com/redoc/public"
          target="_blank"
          rel="noreferrer"
        >
          Developer
        </LinkPages>
        <LinkPages
          href="https://wicrypt.com/token.html"
          target="_blank"
          rel="noreferrer"
        >
          Token
        </LinkPages>
        <LinkPages
          href="https://medium.com/wicrypt"
          target="_blank"
          rel="noreferrer"
        >
          Blog
        </LinkPages>
        {/* <LinkPages  href="https://wicrypt.com/token.html"
          target="_blank"
          rel="noreferrer">Litepaper</LinkPages> */}
        <LinkPages
          href="https://drive.google.com/drive/folders/1SmjSVNkhX0mwGu9j5lIenUEGg3J_nn0A"
          target="_blank"
          rel="noreferrer"
        >
          Media Kits
        </LinkPages>
      </ExternalLinks>
      {/* <CopyRight>&copy; 2021 Wicrypt </CopyRight> */}
      <TokenContainer>
        <div>
          <img height="100" src="/assets/listwnt.svg" alt="logo" />
        </div>
        <PriceSection>
          <div>
            <TokenTitle> Token Price</TokenTitle>
            <TokenPrice> {tokenPrice} USD</TokenPrice>
          </div>
          <div>
            <TokenTitle> Available On</TokenTitle>
            <DFlex>
              <DFlex>
                <img src="./assets/mexc.svg" alt="mexc" />
                <AvailableOn> MEXC</AvailableOn>
              </DFlex>
              <DFlex>
                <img src="./assets/quickswap.svg" alt="mexc" />
                <AvailableOn> Quickswap</AvailableOn>
              </DFlex>
            </DFlex>
          </div>
        </PriceSection>
      </TokenContainer>

      <ExternalLinks>
        <SmallText
          href="https://wicrypt.com/privacy-policy.html"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Privacy Policy
        </SmallText>
        <SmallText
          href="https://wicrypt.com/terms-condition.html"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Terms of Use
        </SmallText>

        <SmallText
          href="https://wicrypt.com/help/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Help
        </SmallText>
      </ExternalLinks>
    </FooterContainer>
  );
};

export default Footer;
