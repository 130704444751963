import { IconCard } from "../reusables/iconCard";
import StakingBalance from "./stakingbalance";
import { StakingTitle, StakingContainer } from "./stakingstyles.js";
import NavBar from "../../components/partials/navbar";
import Footer from "../../components/partials/footer";

const index = () => {
  return (
    <>
      <NavBar />

      <StakingContainer>
        <StakingTitle> Wicrypt Staking Dashboard</StakingTitle>
        <div className="row">
          <div className="col=sm-12">
            <StakingBalance />
          </div>
          <div className="col-lg-3 col-sm-6">
            <IconCard />
          </div>
          {/* <div className="col-lg-3 col-sm-6">
          <IconCard />
        </div> */}
        </div>
      </StakingContainer>
    </>
  );
};

export default index;
