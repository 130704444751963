import _const from "./types";
import axios from "axios";
import config from "../config";
import retrieveAddress from "../retrieve-address";
import mintWicryptDevice from "./mintActions";
import { getFormDetails } from "./generalActions";

//Sends shipment details
export const saveShipmentDetail = (
  streetaddress,
  suitnumber,
  country,
  state,
  zipcode,
  city,
  emailAddress,
  phoneNumber,
  referralCode,
  signature,
  deviceId,
  quantity,
  tokenAddress,
  longitude,
  latitude,
  realPrice,
  firstName,
  lastName
) => {
  const address = retrieveAddress();
  let data = {
    walletAddress: address,
    email: emailAddress,
    phoneNumber: phoneNumber,
    suitNumber: suitnumber,
    streetAddress: streetaddress,
    city: city,
    state: state,
    zipCode: zipcode,
    country: country,
    referralCode: referralCode,
    signedMessageSignature: signature,
    message: process.env.REACT_APP_SIGNATURE_MESSAGE,
    firstName: firstName,
    lastName: lastName,
  };

  return (dispatch) => {
    if (firstName === "") {
      dispatch({
        type: _const.SHIPPING_INFO_ERR,
        payload: "* Please enter your Firstname",
      });
    } else if (lastName === "") {
      dispatch({
        type: _const.SHIPPING_INFO_ERR,
        payload: "* Please enter your LastName",
      });
    } else if (emailAddress == "") {
      dispatch({
        type: _const.SHIPPING_INFO_ERR,
        payload: "* Please enter your Email Address",
      });
    } else if (phoneNumber == "") {
      dispatch({
        type: _const.SHIPPING_INFO_ERR,
        payload: "* Please enter your Phone Number",
      });
    } else if (zipcode == "") {
      dispatch({
        type: _const.SHIPPING_INFO_ERR,
        payload: "* Please enter your Postal Code",
      });
    } else {
      dispatch({
        type: _const.SENDING_SHIPMENT_INFO,
        payload: true,
      });
      dispatch({
        type: _const.SHIPPING_INFO_ERR,
        payload: "",
      });
      axios
        .post(config.apiUrl + `/v2/ShippingInfo`, data)
        .then((res) => {
          dispatch({
            type: _const.SENDING_SHIPMENT_INFO,
            payload: false,
          });

          dispatch(
            mintWicryptDevice(
              deviceId,
              quantity,
              tokenAddress,
              longitude,
              latitude,
              realPrice
            )
          );
        })
        .catch((err) => {
          dispatch({
            type: _const.SHIPPING_INFO_ERR,
            payload: "*" + err.response.data.message,
          });
          dispatch({
            type: _const.SENDING_SHIPMENT_INFO,
            payload: false,
          });
        });
    }
  };
};

//Get shipping info by wallet address
export const getShipmentDetail = (refresh) => {
  const address = retrieveAddress();
  return (dispatch) => {
    dispatch({
      type: _const.GETTING_SHIPMENT_INFO,
      payload: true,
    });

    axios
      .get(
        config.apiUrl +
          `/v2/ShippingInfo/ShippingInfoByWalletAddress?walletAddress=${address}`
      )
      .then((res) => {
        dispatch({
          type: _const.GETTING_SHIPMENT_INFO,
          payload: false,
        });
        dispatch(
          getFormDetails({
            props: ["refresh"],
            value: refresh + 1,
          })
        );
        if (res.data.data) {
          dispatch({
            type: _const.USER_SHIPMENT_INFO,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: _const.GETTING_SHIPMENT_INFO,
          payload: false,
        });
      });
  };
};

//Get token price from coinmarket cap
export const getMarketQuote = () => {
  return (dispatch) => {
    axios
      .get("https://api-calculator.wicrypt.com/api/MarketQuote")
      .then((res) => {
        dispatch({
          type: _const.MARKET_QUOTE,
          payload: res.data.data,
        });
        console.log(res, "i am the res for the quote");
      })
      .catch((err) => {
        console.log(err, "i am the err");
      });
  };
};
