let localPlatforms = ["localhost", "nft-testnet.wicrypt.com"];
let platform = localPlatforms.includes(window.location.hostname)
  ? "local"
  : "production";
let settings = {};

if (platform === "local") {
  settings = {
    apiUrl: process.env.REACT_APP_API_URL,
    appName: "Wicrypt NFT",
    subect: "Wicrypt NFT",
    baseUrl: ""
  };
} else {
  settings = {
    apiUrl: process.env.REACT_APP_API_URL,
    appName: "Wicrypt NFT",
    subect: "Wicrypt NFT",
    baseUrl: ""
  };
}

export default settings;
