import createContract from "../contract-creator";
import deviceAbi from "./abiManager/nft-device.json";
import retrieveAddress from "../../retrieve-address";

async function deviceWhiteList(deviceId: String) {
  try {
    const contract = await createContract(
      deviceAbi,
      process.env.REACT_APP_NFT_CONTRACT
    );
    const ownerAddress = retrieveAddress();
    return await contract.methods.isWhiteListed(deviceId, ownerAddress).call();
  } catch (err) {
    console.log(err);
  }
}

export default deviceWhiteList;
