import { useState } from "react";
import {
  PurchaseSummary,
  SummaryDetail,
  DetailTitle,
  DeviceValue,
  DetailHeading,
  DisapapearingDetail
} from "./successstyles";

import { useSelector } from "react-redux";

const ShippingSummary = () => {
  const [viewShippingSummary, setShippingView] = useState(true);
  const streetaddress = useSelector(state => state.General.streetaddress);
  const suitnumber = useSelector(state => state.General.suitnumber);
  const country = useSelector(state => state.General.country);
  const state = useSelector(state => state.General.state);
  const zipcode = useSelector(state => state.General.zipcode);
  const city = useSelector(state => state.General.city);
  const emailAddress = useSelector(state => state.General.emailAddress);
  const phoneNumber = useSelector(state => state.General.phoneNumber);
  // const firstName = useSelector(state => state.General.firstName);
  // const lastName = useSelector(state => state.General.lastName);

  return (
    <PurchaseSummary>
      <SummaryDetail active={false}>
        <DetailHeading> Shipping Summary</DetailHeading>
        <DetailTitle onClick={() => setShippingView(!viewShippingSummary)}>
          {" "}
          {viewShippingSummary ? (
            <i className="fa fa-angle-up" aria-hidden="true" />
          ) : (
            <i className="fa fa-angle-down" aria-hidden="true" />
          )}
        </DetailTitle>
      </SummaryDetail>
      <DisapapearingDetail active={viewShippingSummary}>
        <SummaryDetail active={true}>
          <DetailTitle> Street address</DetailTitle>
          <DetailTitle>{streetaddress}</DetailTitle>
        </SummaryDetail>
        <SummaryDetail active={true}>
          <DetailTitle> Apartment/ Suit no</DetailTitle>
          <DetailTitle> {suitnumber}</DetailTitle>
        </SummaryDetail>
        <SummaryDetail active={true}>
          <DetailTitle> Country</DetailTitle>
          <DetailTitle> {country}</DetailTitle>
        </SummaryDetail>
        <SummaryDetail active={true}>
          <DetailTitle>State</DetailTitle>
          <DetailTitle>{state}</DetailTitle>
        </SummaryDetail>
        <SummaryDetail active={true}>
          <DetailTitle> City</DetailTitle>
          <DetailTitle> {city}</DetailTitle>
        </SummaryDetail>
        <SummaryDetail active={true}>
          <DetailTitle> Postal Code</DetailTitle>
          <DeviceValue> {zipcode} </DeviceValue>
        </SummaryDetail>
        <SummaryDetail active={true}>
          <DetailTitle> Email address</DetailTitle>
          <DeviceValue> {emailAddress} </DeviceValue>
        </SummaryDetail>
        <SummaryDetail active={false}>
          <DetailTitle> Phone Number</DetailTitle>
          <DeviceValue> {phoneNumber} </DeviceValue>
        </SummaryDetail>
      </DisapapearingDetail>
    </PurchaseSummary>
  );
};

export default ShippingSummary;
