import styled from "styled-components";
import { device } from "../../theme";

export const ComingSoonContainer = styled.div`
  background: ${({ theme }) => theme.mainColor};
  margin: 0;
  padding: 0;
`;

export const HeaderSection = styled.div`
  background: ${({ theme }) => theme.header};
`;

export const LandingContainer = styled.section`
  padding: 50px;
  text-align: center;
  background-image: url("./assets/Map.svg");
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 50px;
  @media ${device.laptop} {
    margin-bottom: 150px;
    padding: 61px;
    padding-bottom: 0px;
  }
`;

export const ImageContainer = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    position: absolute;
    top: -10%;
    right: 8%;
  }
`;

export const DeviceImageContainer = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    position: absolute;
    top: 20%;
    left: 3%;
  }
`;

export const JoinWaitList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 0px;
  p {
    text-align: center;
    font-size: ${({ theme }) => theme.textXXs};
    margin-bottom: 20px;
    font-size: 14px;
    color: ${({ theme }) => theme.flexiblegrey};
  }
`;

export const ComingSoonImageContainer = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    position: absolute;
    top: 10%;
    right: 5%;
  }
`;

export const CardContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  @media ${device.laptop} {
    width: 50%;
    text-align: left;
    padding: 35px;
    padding-left: 0px;
  }
`;

export const LandingTitle = styled.h1`
  font-size: ${({ theme }) => theme.textMd};
  font-weight: ${({ theme }) => theme.textBold};
  color: ${({ theme }) => theme.highlight};
  z-index: 1;
  position: relative;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.textLg};
  }
`;

export const ComingSoonTitle = styled.h1`
  font-size: ${({ theme }) => theme.textSm};
  font-weight: ${({ theme }) => theme.textBold};
  color: ${({ theme }) => theme.textColor};
  z-index: 1;
  position: relative;
  text-align: left;
  margin-bottom: 50px;
  @media ${device.laptop} {
    font-size: 37px;
    text-align: left;
  }
`;
export const CountDownSubTitle = styled.p`
  font-size: ${({ theme }) => theme.textXS};
  color: ${({ theme }) => theme.textColor};
  font-weight: ${({ theme }) => theme.textNormal};
`;

export const CountDownContainer = styled.div`
  background: rgba(212, 212, 212, 0.21);
  backdrop-filter: blur(4.58 px);
  border-radius: 12px;
  padding: 10px;
`;

export const TimerValue = styled.p`
  font-size: 20px;
  color: ${({ theme }) => theme.yellow};
  font-weight: ${({ theme }) => theme.textBold};
  border-right: ${(props) =>
    !props.last ? `1px solid ${props.theme.flexiblegrey}` : "none"};
  margin-bottom: 0px;
  @media ${device.laptop} {
    font-size: 40px;
  }
`;

export const TimerLabel = styled.p`
  margin-bottom: 0px;
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXXs};
  text-align: center;
`;
export const SubTitle = styled.p`
  font-size: ${({ theme }) => theme.textSm};
  color: ${({ theme }) => theme.grey2};
  font-weight: ${({ theme }) => theme.textNormal};
  margin-bottom: 20px;
  z-index: 1;
  position: relative;
`;

export const MintDevice = styled.h1`
  font-size: 55.082px;
  letter-spacing: 0.90155px;
  font-weight: ${({ theme }) => theme.textBold};
  color: ${({ theme }) => theme.white};
  font-size: 36px;
  line-height: 54px;
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.textMd};
    font-weight: ${({ theme }) => theme.textNormal};
    line-height: 83px;
  }
`;

export const MintCard = styled.div`
  box-shadow: 0px 0px 0px 0px #aaaaaa;
  border-radius: 9px;
  background-color: ${({ theme }) => theme.cardcolor};
  position: relative;
  margin-bottom: 30px;
  @media ${device.laptop} {
  }
`;
export const MintHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @media ${device.laptop} {
    margin-bottom: 0px;
  }
`;

export const MintCardBody = styled.div`
  padding: 20px;
  @media ${device.laptop} {
    padding: 20px 30px 40px 30px;
  }
`;

export const ProgressContainer = styled.div`
  width: 100%;
  @media ${device.laptop} {
    width: 50%;
  }
`;

export const CurrencyContainer = styled.div`
  width: 80%;
  @media ${device.laptop} {
    width: 50%;
  }
`;

export const NotConnectedCardBody = styled.div`
  display: flex;
  justify-content: center;
`;

export const MintTitle = styled.p`
  margin-top: 20px;
  color: #858585;
  font-weight: ${({ theme }) => theme.textNormal};
  font-size: 14px;
`;

export const MintValue = styled.p`
  font-weight: ${({ theme }) => theme.textBold};
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXXs};
`;

export const MintSubValue = styled.p`
  margin-bottom: 5px;
  color: #c2c2c2;
  font-weight: 500;
  font-size: ${({ theme }) => theme.textXXs};
`;

export const MintSubTitle = styled.p`
  font-size: 15.4918px;
  line-height: 23px;
  color: ${({ theme }) => theme.grey};
  font-weight: ${({ theme }) => theme.textNormal};
  margin-bottom: 50px;

  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.textXs};
    padding-right: 170px;
  }
`;

export const TokenSymbol = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.textColor};
  margin-bottom: 0px;
`;

export const CurrencySymbol = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
`;

export const DescriptionContainer = styled.div`
  color: ${({ theme }) => theme.lightblackColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  @media ${device.laptop} {
    width: 100%;
  }
`;

export const Button = styled.button`
  padding: 11px 53px;
  border-radius: 30px;
  border: 0px;
  background: #e5b910;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${({ theme }) => theme.white};
`;

export const AboutUs = styled.div`
  font-weight: ${({ theme }) => theme.textNormal};
  letter-spacing: 0.1px;
  text-align: left;
  font-size: ${({ theme }) => theme.textXs};
  width: 100%;
  @media ${device.laptop} {
    width: 40%;
    text-align: left;
  }
`;

export const DeviceOutliers = styled.div`
  width: 50%;
  @media ${device.laptop} {
    width: 100%;
  }
`;

export const NotificationText = styled.p`
  padding: 20px;
  padding-top: 0;
  text-align: center;
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: 14px;
`;

export const CommunityContainer = styled.div`
  padding-top: 50px;
  background-color: rgba(0, 0, 0, 0.03);
`;

export const CommunityTitle = styled.p`
  font-weight: ${({ theme }) => theme.textBold};
  text-align: center;
  font-size: ${({ theme }) => theme.textXs};
  margin-bottom: 30px;
  color: ${({ theme }) => theme.highlight2};
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.textSmd};
  }
`;

export const CommunityOption = styled.div`
  width: 50%;
  margin-bottom: 20px;
  @media ${device.laptop} {
    width: 25%;
    margin-bottom: 50px;
  }
`;
export const CommunityDescription = styled.p`
  font-weight: ${({ theme }) => theme.textNormal};
  text-align: center;
  font-size: ${({ theme }) => theme.textXXs};
  margin-bottom: 70px;
  color: ${({ theme }) => theme.textColor};
`;

export const SocialsName = styled.p`
  //display: none;
  font-weight: ${({ theme }) => theme.textBold};
  font-size: ${({ theme }) => theme.textXs};
  margin-bottom: 0px;
  color: ${({ theme }) => theme.highlight2};
  opacity: 0.9;
  text-align: left;
  @media ${device.laptop} {
    display: block;
  }
`;

export const Underline = styled.div`
  border-bottom: none;
  @media ${device.laptop} {
    border-bottom: 1px solid #efefef;
  }
`;

export const DeviceBox = styled.div`
  border: 2px solid #e5b910;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.yellow};
  background-image: linear-gradient(
    180deg,
    rgba(26, 26, 26, 0) 0%,
    rgba(26, 26, 26, 0) 54.29%,
    rgba(26, 26, 26, 0.8) 100%
  );
  transform: ${(props) =>
    props.active ? "scale(1.2, 1.2)" : "scale(0.8, 0.8)"};
  opacity: ${(props) => (props.active ? 1 : 0.2)};
`;

export const InputBox = styled.input`
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 7px;
  width: 100px;
  text-align: center;
  background: transparent;
  color: ${({ theme }) => theme.lightblackColor};
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
`;

export const MintQuantity = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

export const MintTotalSection = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const MintRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 1px;
  @media ${device.laptop} {
    gap: 30px;
  }
`;

export const ActionBox = styled.div`
  background: ${(props) =>
    props.active ? props.theme.yellow : props.theme.inactive};
  color: ${({ theme }) => theme.white};
  width: 30px;
  height: 30px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
  @media ${device.laptop} {
    display: flex;
  }
`;

export const QuantityActionBox = styled.div`
  background: ${(props) =>
    props.active ? props.theme.yellow : props.theme.inactive};
  color: ${({ theme }) => theme.white};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
`;

export const LargeImageBox = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
  }
`;

export const DeviceImageBox = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    position: absolute;
    top: 50%;
    left: 40%;
  }
`;

export const VisitProductStageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  padding-bottom: 30px;

  a {
    text-decoration: none;
    color: rgba(229, 185, 16, 1);
  }
`;

export const SocialsContainer = styled.div`
  text-align: center;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  align-items: center;
  align-content: center;
  justify-content: center;

  @media ${device.laptop} {
    padding: 0px 120px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(211, 211, 211, 0.4);
  border-radius: 8px;
  margin-right: 20px;
  padding: 5px;
  height: 50px;
  width: 50px;
`;

export const IconText = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

export const MintCalculated = styled.p`
  font-weight: ${({ theme }) => theme.textNormal};
  font-size: 18px;
  color: ${({ theme }) => theme.textColor};
  margin-bottom: 20px;
`;

export const Error = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.error};
`;

export const SelectCurrenctBox = styled.div`
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const Currency = styled.span`
  color: ${({ theme }) => theme.textColor2};
  font-size: ${({ theme }) => theme.textXXs};
  margin-left: 10px;
`;

export const CurrencyValue = styled.span`
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXs};
  margin-right: 10px;
`;

export const DeviceContainer = styled.div`
  border: ${(props) =>
    props.active ? "2.85057px solid #e5b910" : "2.85057px solid #E5E5E5"};
  box-sizing: border-box;
  border-radius: 21.3793px;
  padding: 18px;
  transform: ${(props) => (props.active ? "scale(1, 1)" : "scale(0.6, 0.6)")};
  width: 80%;
  display: flex;
  align-content: center;
  justify-content: center;
  @media ${device.laptop} {
    width: 34%;
  }
  
}
`;

export const TokenContainer = styled.div`
  background: ${({ theme }) => theme.tokenbtn};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.03);
  border-radius: 40px;
  padding: 15px;
  color: ${({ theme }) => theme.highlight};
  font-weight: bold;
`;

export const DeviceNamelabel = styled.div`
  position: absolute;
  color: #ffffff;
  right: 10%;
  font-size: 0.8rem;
  bottom: 10%;
  font-weight: bold;
  padding: 5px;
  @media ${device.laptop} {
    right: 20%;
  }
`;

export const Dropdown = styled.div`
  background: ${({ theme }) => theme.mainColor};
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 15%;
  padding: 10px;
  border-radius: 8px;
  z-index: 1000;
`;

export const AdContainer = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXXs};
  border: ${({ theme }) => `1px solid ${theme.grey}`};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
  a {
    color: ${({ theme }) => theme.yellow} !important;
    text-decoration: none;
  }
`;

export const Badge = styled.div`
  position: absolute;
  right: 3%;
  top: 10%;
  //transform: rotate(-25deg);
  //border: ${({ theme }) => `1px solid ${theme.grey}`};
  padding: 10px;
  background-color: ${({ theme }) => theme.yellow};
  color: ${({ theme }) => theme.white};
  border-radius: 50%;
  font-size: ${({ theme }) => theme.textXXs};
  font-weight: 600;
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-wrap: noWrap;
  overflow: hidden;
  gap: 10px;
  margin: 0px;
  //margin: 0px 20px 30px; 20px;
`;
export const CarouselItem = styled.a`
  min-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  transform: ${(props) => `translate(-${props.currentIndex * 100}%)`};
  @media ${device.laptop} {
    min-width: 50%;
    width: 50%;
    transform: none;
  }
`;

export const CarouselItem2 = styled.div`
  min-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  transform: ${(props) => `translate(-${props.currentIndex * 100}%)`};
  @media ${device.laptop} {
    min-width: 50%;
    width: 50%;
    transform: none;
  }
`;

export const Note = styled.div`
  color: ${({ theme }) => theme.error};
  font-weight: 600;
`;

export const MintContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  padding-top: 0px;
  align-content: center;
  align-items: center;
  justify-content: center;
  @media ${device.laptop} {
    padding: 50px;
    padding-top: 0px;
  }
`;

export const SideIcon = styled.div`
  display: none;

  @media ${device.laptop} {
    animation: smoothmove 4s ease-in-out infinite;
    display: block;
    position: absolute;
    left: 0;
    top: 40%;
  }
`;

export const RightIcon = styled.div`
  display: none;
  @media ${device.laptop} {
    animation: smoothmove 4s ease-in-out infinite;
    display: block;
    position: absolute;
    right: 0;
  }
`;

export const BlurryIcon = styled.div`
  position: absolute;
  left: -20px;
  top: 40%;
`;

export const MintCardContainer = styled.div`
  width: 100%;
  @media ${device.laptop} {
    width: 40%;
  }
`;

export const Section = styled.div`
  margin: 0;
  background-color: ${({ theme }) => theme.mainColor};
  padding: 20px;
  @media ${device.laptop} {
    padding: 100px;
  }
`;

export const CommunitySection = styled.div`
  //margin: 100px;
  background-color: ${({ theme }) => theme.mainColor};
  padding: 0px;
  padding-top: 20px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: ${({ theme }) => theme.textSm};
  line-height: 60px;
  text-align: center;
  margin-bottom: 0px;
  @media ${device.laptop} {
    text-align: left;
    font-size: ${({ theme }) => theme.textSmd};
  }
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;
`;

export const WicryptDevices = styled.div`
  width: 100%;
  position: relative;
  height: 350px;
  @media ${device.laptop} {
    width: 60%;
  }
`;

export const OutlierContainer2 = styled.div`
  background: #f4f2ea;
  border: 1px solid #c4c4c4;
  border-radius: 35.2274px;
  width: 188.83px;
  height: 188.83px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: absolute;
  right: 8%;
  top: 48%;
  @media ${device.laptop} {
    width: 364.47px;
    height: 364.47px;
    right: -4%;
  }
`;

export const OutlierContainer1 = styled.div`
  position: absolute;
  right: 44%;
  top: 0;
  background: #f4f2ea;
  border: 1px solid #c4c4c4;
  border-radius: 35.2274px;
  width: 188.83px;
  height: 188.83px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media ${device.laptop} {
    width: 364.47px;
    height: 364.47px;
    top: -15%;
    right: 33%;
  }
`;

export const DeviceImg = styled.img`
  width: 152.84px;
  height: 152.84px;
  @media ${device.laptop} {
    width: 337.5px;
    height: 337.5px;
  }
`;

//Others css
export const Card = styled.div`
  padding: 30px;
  background: ${(props) =>
    props.level === 1 ? props.theme.otherCard2 : props.theme.otherCard};
  width: 348px;
  height: 356px;
  border-radius: 20px;
  position: relative;
  @media ${device.laptop} {
    padding: 50px;
    min-height: 474px;
    width: 100%;
  }
`;

export const ButtonContainer = styled.a`
  margin-top: 30px;
`;

export const CardImage = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const OtherImg = styled.img`
  height: 50px;
  width: auto;
  @media ${device.laptop} {
    height: auto;
    width: auto;
  }
`;
export const CardImage2 = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const OtherCardTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.43333px;
  color: ${(props) => (props.level === 1 ? props.theme.mainColor : "#25346A")};
  @media ${device.laptop} {
    font-size: 48px;
    line-height: 72px;
  }
`;

export const OtherCardSubtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: ${(props) => (props.level === 1 ? props.theme.mainColor : "#0B0E11")};
  padding-right: 0px;
  margin-bottom: 30px;
  @media ${device.laptop} {
    font-size: 18px;
    padding-right: 50px;
  }
`;

export const TermaandConditions = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.grey};
  margin-top: 10px;
  font-weight: 500;
`;

export const RadioButton = styled.input`
  margin-right: 10px;
  cursor: pointer;
`;
