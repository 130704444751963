import devicePrice from "./device-price";
import deviceDecimals from "./device-decimals";
import _const from "../../actions/types";

function deviceSum(deviceId: any, tokenAddress: String) {
  return async (dispatch: Function) => {
    try {
      // // get the price of the selected device
      const price = await devicePrice(deviceId, tokenAddress);
      const decimals = await deviceDecimals(tokenAddress);
      var num = Math.pow(10, decimals);
      var total = price / num;
      var formattedSum = total.toFixed(4);
      dispatch({
        type: _const.DEVICE_PRICE,
        payload: { formattedSum, price }
      });
    } catch (err) {
      throw err;
    }
  };
}

export default deviceSum;
