import createContract from "../contract-creator";
import deviceAbi from "./abiManager/nft-device.json";
import retrieveAddress from "../../retrieve-address";
import getFastGasFeeMatic from "./gas-fee";

async function mintDevice(
  deviceId: String,
  quantity: Number,
  tokenAddress: String,
  longitude: String,
  latitude: String
) {
  try {
    const ownerAddress = retrieveAddress();
    const contract = await createContract(
      deviceAbi,
      process.env.REACT_APP_NFT_CONTRACT
    );
    let gasFee = await getFastGasFeeMatic();
    return await contract.methods
      .mint(deviceId, quantity, tokenAddress, longitude, latitude)
      .send({ from: ownerAddress, gasPrice: gasFee });
  } catch (err) {
    return err;
    console.log(err);
  }
}

export default mintDevice;
