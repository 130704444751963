import styled from "styled-components";

export const IconCard = ({ label, error, errorText, ...rest }) => {
  return (
    <CardContainer>
      <Title> Total Staked</Title>
      <Value>
        {" "}
        20,771,643 <Currency> WNT</Currency>
      </Value>
      <SubValue> $3.12b</SubValue>
      <Badge>
        <img src="./assets/scale.svg" height="20" alt="scale" />
      </Badge>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  padding: 20px;
  background: #2b2f36;
  box-shadow: 0px 0px 8.17112px rgba(45, 58, 58, 0.02);
  border-radius: 8.17112px;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const Title = styled.p`
  color: ${({ theme }) => theme.grey2};
  font-size: ${({ theme }) => theme.textXXs};
  margin-bottom: 10px;
  font-weight: ${({ theme }) => theme.textBold};
`;
const Value = styled.p`
  color: ${({ theme }) => theme.textColor};
  font-size: ${({ theme }) => theme.textSm};
  font-weight: ${({ theme }) => theme.textBold};
  margin-bottom: 0px;
`;
const Currency = styled.span`
  font-size: ${({ theme }) => theme.textXXs};
`;
const SubValue = styled.span`
  color: ${({ theme }) => theme.grey2};
  font-size: ${({ theme }) => theme.textXXs};
`;
const Badge = styled.div`
  padding: 5px;
  background: #e5b910;
  border-radius: 0px 0px 4.95238px 4.95238px;
  position: absolute;
  top: 0;
  right: 10%;
`;
