import mintDevice from "../contracts/devices/mint-devices";
import approveMint from "../contracts/devices/approve-mint";
import allDeviceCount from "../contracts/devices/all-device-count";
import deviceWhiteList from "../contracts/devices/device-whitelisted";
import _const from "../actions/types";
import { notify } from "react-notify-toast";

function mintWicryptDevice(
  deviceId: String,
  quantity: number,
  tokenAddress: String,
  longitude: String,
  latitude: String,
  price: String
) {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: _const.APPROVING_MINT,
        payload: true
      });
      const whitelisted = await deviceWhiteList(deviceId);
      if (!whitelisted[0]) {
        const approve = await approveMint(price, quantity, tokenAddress);
      }
      const res = await mintDevice(
        deviceId,
        quantity,
        tokenAddress,
        longitude.toString(),
        latitude.toString()
      );
      if (res) {
        dispatch({
          type: _const.APPROVING_MINT,
          payload: false
        });
        localStorage.removeItem("MINT_PROPERTIES");
        if (res.status) {
          let myColor = {
            background: "green",
            text: "#FFFFFF",
            padding: "10px",
            borderRadius: "8px",
            fontSize: "0.8rem"
          };
          notify.show("Transaction Successful!", "custom", 5000, myColor);
          dispatch({
            type: _const.SUCCESSFUL_APPROVE_MINT,
            payload: res
          });
        } else {
          let myColor = {
            background: "red",
            text: "#FFFFFF",
            padding: "10px",
            borderRadius: "8px",
            fontSize: "0.8rem"
          };
          notify.show("Transaction Failed!", "custom", 5000, myColor);
        }
        dispatch(allDeviceCount());
      }
    } catch (err) {
      dispatch({
        type: _const.APPROVING_MINT,
        payload: false
      });

      throw err;
    }
  };
}

export default mintWicryptDevice;
