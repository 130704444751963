import styled from "styled-components";
import { device } from "../../theme";

export const NavBarContainer = styled.nav`
  padding: 20px;
  margin-bottom: 30px;
  @media ${device.laptop} {
    margin-bottom: 50px;
    padding: 50px;
    padding-bottom: 20px;
  }
`;

export const SmallText = styled.a`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.grey2};
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 18px;
  text-decoration: none;
  width: 30%;
  margin-top: 20px;
`;

export const FooterContainer = styled.footer`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  @media ${device.laptop} {
    margin-top: 0px;
  }
`;

export const CopyRight = styled.p`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.lightblackColor};
`;

export const ExternalLinks = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;
  @media ${device.laptop} {
    width: 30%;
  }
`;
export const LinkPages = styled.a`
  font-size: ${({ theme }) => theme.textXXs};
  color: #6f6f6f;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  width: 30%;
  margin-top: 20px;
`;
export const TokenContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 30px;
  gap: 10px;
  @media ${device.laptop} {
    width: 40%;
  }
`;
export const TokenTitle = styled.p`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.highlight};
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;
export const TokenPrice = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.flexiblegrey};
  margin-bottom: 10px;
  @media ${device.laptop} {
    margin-bottom: 0px;
  }
`;
export const AvailableOn = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) => theme.textXXs};
  line-height: 18px;
  color: #6f6f6f;
`;
export const PriceSection = styled.div`
  display: grid;

  @media ${device.laptop} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
`;
export const DFlex = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-contnet: center;
  align-items: center;
  gap: 10px;
`;
export const FooterLogoContainer = styled.div`
  width: 100%;
`;

export const Button = styled.button`
  padding: 11px 53px;
  border-radius: 30px;
  border: 0px;
  background: #e5b910;
  font-weight: 600;
  color: ${({ theme }) => theme.white};

  &:disabled {
    background-color: ${({ theme }) => theme.disabledColor};
  }
`;
export const NavList = styled.ul`
  display: none;
  @media ${device.laptop} {
    display: inline-flex;
    float: right;
    list-style: none;
    align-items: center;
  }
`;
export const MobileNavList = styled.ul`
  list-style: none;
  align-items: center;
  cursor: pointer;
`;
export const LinkItems = styled.li`
  font-size: ${({ theme }) => theme.textXs};
  color: ${({ theme }) => theme.mainColor};
  margin-left: 0px;
  font-weight: 500;
  cursor: pointer;
  list-style-type: none !important;
  @media ${device.laptop} {
    margin-left: 30px;
  }
`;

export const MobileNav = styled.div`
  float: right;
  display: inline-flex;
  @media ${device.laptop} {
    display: none;
  }
`;
export const MobileNavToggleContainer = styled.div`
  cursor: pointer;
  margin-left: 20px;
`;

export const MobileNavToggleBar = styled.div`
  width: 30px;
  height: 4px;
  background-color: ${({ theme }) => theme.flexiblegrey};
  margin: 6px 0;
  transition: 0.4s;
  transform: ${(props) => (props.active ? "rotate(25deg)" : "none")};
`;

export const MobileNavContainer = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.mainColor};
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100000;
  top: 10%;
  right: 5%;
  padding: 10px;
  border-radius: 8px;
`;
