import { useState } from "react";
import {
  DeviceBox,
  PurchaseSummary,
  SummaryDetail,
  DetailTitle,
  DeviceValue,
  DetailHeading,
  Back,
  DeviceCenter,
  DisapapearingDetail,
} from "./successstyles";
import { ButtonState } from "../reusables/button";
import { useDispatch, useSelector } from "react-redux";
import retrieveAddress from "../methods/retrieve-address";
import { Link } from "react-router-dom";
import { clearData } from "../methods/actions/generalActions";
import ShippingSummary from "./shippingsummary";

const Success = () => {
  const dispatch = useDispatch();
  const formattedPrice = useSelector((state) => state.General.formattedPrice);
  const quantity = useSelector((state) => state.General.quantity);
  const alldevices = useSelector((state) => state.DeviceReducer.alldevices);
  const activeDevice = useSelector((state) => state.General.activeDevice);
  const tokenAddress = useSelector((state) => state.General.tokenAddress);
  const ownerAddress = retrieveAddress();

  const [viewPurchaseSummary, setPurchaseView] = useState(true);

  return (
    <div className="container">
      <div className="col-lg-12">
        <div className="col-lg-10 offset-lg-1">
          <Link to="/" className="link-decoration">
            <Back onClick={() => dispatch(clearData())}>
              {" "}
              <i className="fa fa-arrow-left mr-1" aria-hidden="true" /> Back to
              Home
            </Back>
          </Link>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="col-lg-6 col-sm-12 col-xs-12 offset-lg-3">
          <DeviceCenter>
            <DeviceBox>
              <img
                src={
                  activeDevice === 0 ? "/assets/lynx.svg" : "/assets/spider.svg"
                }
                className="img-fluid"
                alt={"device"}
              />
            </DeviceBox>
          </DeviceCenter>
          <ShippingSummary />
          <PurchaseSummary>
            <SummaryDetail active={false}>
              <DetailHeading> Purchase Summary</DetailHeading>
              <DetailTitle
                onClick={() => setPurchaseView(!viewPurchaseSummary)}
              >
                {" "}
                {viewPurchaseSummary ? (
                  <i className="fa fa-angle-up" aria-hidden="true" />
                ) : (
                  <i className="fa fa-angle-down" aria-hidden="true" />
                )}
              </DetailTitle>
            </SummaryDetail>
            <DisapapearingDetail active={viewPurchaseSummary}>
              <SummaryDetail active={true}>
                <DetailTitle> Device Type</DetailTitle>
                <DetailTitle>
                  {alldevices.length > 0 ? alldevices[activeDevice][0] : ""}
                </DetailTitle>
              </SummaryDetail>
              <SummaryDetail active={true}>
                <DetailTitle> Price</DetailTitle>
                <DetailTitle>
                  {" "}
                  {formattedPrice} {tokenAddress.tokenSymbol}
                </DetailTitle>
              </SummaryDetail>
              <SummaryDetail active={true}>
                <DetailTitle> Number of Devices</DetailTitle>
                <DetailTitle> {quantity}</DetailTitle>
              </SummaryDetail>
              <SummaryDetail active={true}>
                <DetailTitle> Blockchain</DetailTitle>
                <DetailTitle>Polygon</DetailTitle>
              </SummaryDetail>
              <SummaryDetail active={true}>
                <DetailTitle> Wallet Address</DetailTitle>
                <DetailTitle> {ownerAddress}</DetailTitle>
              </SummaryDetail>
              <SummaryDetail active={false}>
                <DetailTitle> Total amount</DetailTitle>
                <DeviceValue>
                  {" "}
                  {quantity * formattedPrice} {tokenAddress.tokenSymbol}
                </DeviceValue>
              </SummaryDetail>
            </DisapapearingDetail>
          </PurchaseSummary>
        </div>
      </div>
      <div className="col-lg-12">
        <a href="https://scan.wicrypt.com/" target="_blank">
          <div className="t-center mt-5">
            <ButtonState
              buttonClass="primary"
              // onClick={handleScrollToElement}
              label={"View in Explorer"}
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Success;
