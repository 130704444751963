import _const from "../actions/types";

const initialState = {
  approvingMint: false,
  sendingShipment: false,
  gettingShippingInfo: false
};

const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case _const.APPROVING_MINT:
      return { ...state, approvingMint: action.payload };

    case _const.SENDING_SHIPMENT_INFO:
      return { ...state, sendingShipment: action.payload };

    case _const.GETTING_SHIPMENT_INFO:
      return { ...state, gettingShippingInfo: action.payload };

    default:
      return state;
  }
};

export default LoaderReducer;
