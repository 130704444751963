import _const from "../actions/types";

type Action = {
  type: string;
  payload: any;
};

const initialState = {
  alldevices: []
};

const DeviceReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case _const.ALL_DEVICES:
      return { ...state, alldevices: action.payload };

    default:
      return state;
  }
};

export default DeviceReducer;
