import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/home";
import Shipping from "./components/shipping";
import Staking from "./components/staking";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/shipping" component={Shipping} />
        <Route exact path="/staking" component={Staking} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
