import createContract from "../web3Initiate";
import deviceAbi from "./abiManager/nft-device.json";

async function deviceDetails(count: Number) {
  try {
    const contract = await createContract(
      deviceAbi,
      process.env.REACT_APP_NFT_CONTRACT
    );
    let devices = [];
    for (let i = 0; i < count; i++) {
      let device = await contract.methods.getDeviceType(i + 1).call();
      devices.push(device);
    }
    return devices;
  } catch (err) {
    console.log(err);
    return [];
  }
}

export default deviceDetails;
