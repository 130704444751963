import { useState, useEffect } from "react";
import { ButtonState } from "../reusables/button";
import {
  CarouselContainer,
  CarouselItem2,
  Card,
  OtherCardTitle,
  OtherCardSubtitle,
  ButtonContainer,
  CardImage,
  CardImage2,
  OtherImg,
} from "./homestyles";

const OtherCard = () => {
  return (
    <Card level={1}>
      <OtherCardTitle level={1}> The Outliers</OtherCardTitle>
      <OtherCardSubtitle level={1}>
        {" "}
        Be one of the few originals to enjoy truly decentralized internet
      </OtherCardSubtitle>
      <ButtonContainer>
        <ButtonState
          buttonClass={"secondary"}
          fontSize={"18px"}
          label={"Mint Device Now"}
        />
      </ButtonContainer>
      <CardImage>
        <OtherImg src="./assets/cardimg.svg" alt="cardImg" />
      </CardImage>
    </Card>
  );
};
const SecondOtherCard = () => {
  return (
    <Card level={2}>
      <OtherCardTitle level={2}> Wicrypt Explorer</OtherCardTitle>
      <OtherCardSubtitle level={2}>
        {" "}
        The Wicrypt Explorer Is An Intelligent Mapping System That Allows Users
        To See All The Wicrypt Hotspot Hubs Near Them. Providing Easy Connection
        And Seamless Experience.
      </OtherCardSubtitle>
      <a href="https://scan.wicrypt.com/" target="_blank" rel="noreferrer">
        <ButtonContainer>
          <ButtonState
            buttonClass={"secondary"}
            fontSize={"18px"}
            label={"Visit Explorer"}
          />
        </ButtonContainer>
      </a>
      <CardImage2>
        <OtherImg src="./assets/cardimg2.svg" alt="cardImg" />
      </CardImage2>
    </Card>
  );
};

const Other = () => {
  const data = [OtherCard, SecondOtherCard];
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselInfinteScroll = () => {
    if (currentIndex === data.length - 1) {
      return setCurrentIndex(0);
    }

    return setCurrentIndex(currentIndex + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      carouselInfinteScroll();
    }, 3000);
    return () => clearInterval(interval);
  });

  return (
    <CarouselContainer>
      {data.map((each, i) => {
        return (
          <CarouselItem2 key={i} currentIndex={currentIndex}>
            {each()}
          </CarouselItem2>
        );
      })}
    </CarouselContainer>
  );
};

export default Other;
