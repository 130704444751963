import styled from "styled-components";
//import { device } from "../../theme";

export const Back = styled.p`
  color: ${({ theme }) => theme.highlight};
  font-weight: bold;
  font-size: ${({ theme }) => theme.textXXs};
  cursor: pointer;
`;

export const ShippingPage = styled.div`
  background: ${({ theme }) => theme.mainColor};
  padding-bottom: 50px;
`;

export const ShippingCard = styled.div`
  background: ${({ theme }) => theme.summarybox};
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
`;

export const DetailHeading = styled.p`
  color: ${({ theme }) => theme.cardBlue};
  font-weight: bold;
  font-size: ${({ theme }) => theme.textXs};
  margin-left: 15px;
`;

export const InputBox = styled.input`
  color: ${({ theme }) => theme.flexiblegrey};
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(196, 196, 196, 0.3);
  border-radius: 0;
  font-size: ${({ theme }) => theme.textXXs};
  margin-top: 20px;
  margin-bottom: 20px;
  &::placeholder {
    font-size: ${({ theme }) => theme.textXXs};
    color: ${({ theme }) => theme.flexiblegrey};
  }
  :hover {
    color: ${({ theme }) => theme.flexiblegrey};
    background-color: transparent;
    border: none;
    background-color: transparent;
    outline: none;
    border-bottom: 2px solid rgba(196, 196, 196, 0.3);
    transition: all 500ms ease-in-out;
  }
  :focus {
    color: ${({ theme }) => theme.flexiblegrey};
    outline: none;
    border: none;
    background-color: transparent;
    box-shadow: none;
    border-bottom: 2px solid rgba(196, 196, 196, 0.3);
    transition: all 500ms ease-in-out;
  }
  :focus-visible {
    outline: none;
  }
  :disabled {
    background-color: transparent;
  }
`;

export const FlexInputs = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BreadCrumbs = styled.div`
  display: inline-flex;
  margin-bottom: 20px;
`;

export const BreadCrumbPresent = styled.p`
  font-size: ${({ theme }) => theme.textXs};
  color: ${({ theme }) => theme.flexiblegrey};
`;

export const BreadCrumbParent = styled.div`
  color: ${({ theme }) => theme.cardBlue};
  font-weight: bold;
  font-size: ${({ theme }) => theme.textXs};
  margin-right: 30px;
`;

export const DropDownContent = styled.div`
  position: absolute;
  max-height: 150px;
  min-width: 300px;
  overflow-y: scroll;
  box-shadow: 0 10px 20px #0000002f;
  background: ${({ theme }) => theme.summarybox};
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXXs};
  padding: 10px;
`;

export const DropDownItem = styled.p`
  cursor: pointer;
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXXs};
  :hover {
    color: ${({ theme }) => theme.highlight};
  }
`;

export const DropDownArrow = styled.i`
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXXs};
  font-weight: 400;
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 40%;
`;

export const InfoTitle = styled.p`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: ${({ theme }) => theme.textXs};
  margin-left: 15px;
  margin-bottom: 10px;
`;

export const InfoValue = styled.p`
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXXs};
  margin-left: 15px;
`;

export const InfoHolder = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
  align-items: center;
`;

export const LeftInfo = styled.div`
  width: 60%;
`;

export const RightInfo = styled.div`
  width: 40%;
`;

export const EditButton = styled.button`
  background: ${({ theme }) => theme.editbutton};
  border-radius: 23.7773px;
  font-size: 0.8rem;
  min-width: 20%;
  font-weight: bold;
  color: ${({ theme }) => theme.edittextColor};
`;

export const CenterLoader = styled.div`
  margin-top: 150px;
  text-align: center;
  min-height: 300px;
`;

export const ErrMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  text-align: center;
`;
